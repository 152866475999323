import image1 from "../assets/ilyasasad.jpeg";
import image2 from "../assets/imran.jpeg";
import image3 from "../assets/shaan.jpeg";

export const TrainersData = [
  {
    image: image1,
    experience: "I have 8 years experience of body building ",
    name: "ILYASASAD KHAN",
    status: "TRAINER",
  },
  {
    image: image2,
    experience: "5 years experience of body building",
    name: "IMRAN KHAN",
    status: "COACH",
  },
  {
    image: image3,
    experience: " 4 years experience of body building",
    name: "SHAAN KHAN",
    status: "TRAINER",
  },
];
